// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Bootstrap: Modal
$modal-fade-transform: translate(0, 50px);
$modal-transition: transform .2s ease-in-out ;

// Brown color
$brown-light: #9c8882;
$brown: #382615;
$brown-dark: #140e0d;

$primary-color: #382615;
