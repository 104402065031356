/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 11, 2022 */



@font-face {
    font-family: 'Bkant';
    src: url('BKANT.woff2') format('woff2'),
         url('BKANT.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
